import { TransactionsTableColumn } from '@/types'

export default {
  transaction: {
    table: {
      transactions: {
        [TransactionsTableColumn.Type]: 'Type',
        [TransactionsTableColumn.Time]: 'Time',
        [TransactionsTableColumn.Block]: 'Block',
        [TransactionsTableColumn.Status]: 'Status',
        [TransactionsTableColumn.Amount]: 'Amount',
        [TransactionsTableColumn.TxnHash]: 'Txn Hash',
        [TransactionsTableColumn.TxHashAndType]: 'Tx Hash/Type',
        [TransactionsTableColumn.StatusAndAmount]: 'Status/Amount'
      }
    },
    txs: 'Txs',
    fee: 'Fee',
    type: 'Type',
    hash: 'Hash',
    time: 'Time',
    memo: 'Memo',
    view: 'View',
    items: 'Items',
    height: 'Height',
    events: 'Events',
    sender: 'Sender',
    newTxs: 'New Txs',
    txHash: 'Tx Hash',
    message: 'Message',
    summary: 'Summary',
    txnHash: 'Txn Hash',
    messages: 'Messages',
    errorLog: 'Error Log',
    exportCsv: 'Export CSV',
    messageType: 'Message Type',
    tokenTransfers: 'Token Transfers',
    defiActivities: 'DeFi Activities',
    rawTransaction: 'Raw Transaction',
    transactionHash: 'Transaction Hash',
    updateTransactions: 'Update Transactions',
    transactionDetails: 'Transaction details',
    gasUsedAndRequested: 'Gas (Used/Requested)',
    noTransactionsFound: 'No transactions found.',

    filter: {
      types: 'Types',
      reset: 'Reset',
      filters: 'Filters',
      allTypes: 'All Types',
      messageType: 'Message Type',
      selectAll: 'Select All',
      clearAll: 'Clear All',
      resetMessages: 'Reset Messages'
    },

    stats: {
      totalTransactions: 'Total Transactions',
      transactionsThirtyDays: 'Transactions (30d)',
      transactionsLast24Hours: 'Transactions (Last 24h)',
      tps: 'TPS (Last 100 Blocks)'
    },

    voter: 'Voter',
    signer: 'Signer',
    details: 'Details',
    proposer: 'Proposer',
    receiver: 'Receiver',
    depositor: 'Depositor',
    validator: 'Validator',
    subaccount: 'Subaccount',
    orchestrator: 'Orchestrator',

    hashInvalidTitle: 'Oops! An invalid Tx hash has been entered:',
    hashInvalidDescription: 'Please re-enter the transaction hash correctly.',
    notFoundTitle:
      'Sorry, the following transaction was not found on {network}',
    notFoundDescription:
      'The Injective Explorer only provides an overview of the current state of the blockchain such as your transaction status. The Injective Explorer does NOT control any transaction.',

    notFoundDescriptionPoint1:
      '1) If you have just submitted a transaction, please wait at least 30 seconds before refreshing this page.',
    notFoundDescriptionPoint2:
      '2) Your transaction could still be in the TX pool of a different node, waiting to be broadcasted.',
    notFoundDescriptionPoint3:
      '3) If the network is busy, it can take some time for your transaction to appear.',
    notFoundDescriptionPoint4:
      '4) If your transaction still does not appear after 1 hour, please check with your sender or exchange/wallet/transaction provider for additional information.',

    proposal: {
      proposal: 'proposal',
      yes: 'yes',
      abstain: 'abstain',
      no: 'no',
      noWithVeto: 'no with veto'
    },

    summaryType: {
      MsgExecuteContractCompatSwap:
        '{sender} swapping {inputAmount} {inputSymbol} for {outputAmount} {outputSymbol}'
    },

    eventLogs: {
      title: 'Event Logs',
      logs: 'logs'
    },

    claimId: 'Claim #',
    claimIdNote:
      'Multiple MsgDepositClaim transactions are executed for an Ethereum deposit. Each deposit is identified by an unique claimId.',

    generatingCsvSuccessMessage: 'Successfully generated CSV file!',
    generatingCsvErrorMessage:
      'There is an issue generating the CSV file. Please try again.'
  }
}
