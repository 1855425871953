<script lang="ts" setup></script>

<template>
  <div class="pt-10 pb-8 text-center">
    <SharedIcon name="not-found" class="mx-auto mb-12 w-20 h-20" />
    <p class="tracking-0.4 text-xl font-bold">
      <slot name="title" />
    </p>
    <p class="tacking-0.16 my-4 text-lg break-all">
      <slot name="value" />
    </p>
    <span class="tracking-0.4 text-xl font-bold">
      <slot name="description" />
    </span>
  </div>
</template>
