<script lang="ts" setup>
useHead({
  bodyAttrs: {
    class: 'font-sans text-grey-50 bg-inj-black dark:bg-inj-black text-base'
  }
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage
      :transition="{
        name: 'fade',
        mode: 'out-in'
      }"
    />
  </NuxtLayout>
</template>
