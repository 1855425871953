import {
  Pages,
  MarketsSubPage,
  MarketsTableColumn,
  FundingRateTableColumn,
  TradingRulesTableColumn,
  RealTimeFundingRateTableColumn
} from '@/types'

export default {
  markets: {
    table: {
      fundingRate: {
        [FundingRateTableColumn.Time]: 'Time',
        [FundingRateTableColumn.FundingRate]: 'Funding Rate',
        [FundingRateTableColumn.FundingInterval]: 'Funding Interval'
      },
      realTimeFundingRate: {
        [RealTimeFundingRateTableColumn.Markets]: 'Market',
        [RealTimeFundingRateTableColumn.NextFunding]: 'Next Funding',
        [RealTimeFundingRateTableColumn.HourlyInterestRate]:
          'Hourly Interest Rate',
        [RealTimeFundingRateTableColumn.EstimatedFundingRate]:
          'Estimated Funding Rate'
      },
      tradingRules: {
        [TradingRulesTableColumn.Markets]: 'Market',
        [TradingRulesTableColumn.MinNotional]: 'Min Notional',
        [TradingRulesTableColumn.BaseMakerFee]: 'Base Maker Fee',
        [TradingRulesTableColumn.BaseTakerFee]: 'Base Taker Fee',
        [TradingRulesTableColumn.MinPriceMovement]: 'Min Price Movement',
        [TradingRulesTableColumn.MinAmountMovement]: 'Min Amount Movement',
        [TradingRulesTableColumn.InitialMarginRatio]: 'Initial Margin Ratio',
        [TradingRulesTableColumn.MaintenanceMarginRatio]:
          'Maintenance Margin Ratio'
      },
      marketInfo: {
        [MarketsTableColumn.Type]: 'Type',
        [MarketsTableColumn.Price]: 'Price',
        [MarketsTableColumn.Markets]: 'Market',
        [MarketsTableColumn.Volume]: 'Volume (24h)',
        [MarketsTableColumn.VolumeInUsd]: 'Volume (24h) in USD Value'
      }
    },
    allMarkets: 'All Markets',
    allTypes: 'All Types',
    baseDenom: 'Base Denom',
    baseMakerFee: 'Base Maker Fee',
    baseTakerFee: 'Base Taker Fee',
    derivative: 'Derivative',
    estimatedFundingRate: 'Estimated Funding Rate',
    exchangeRelayers: 'Exchanges / Relayers',
    fundingInterval: 'Funding Interval',
    fundingRate: 'Funding Rate',
    hourlyInterestRate: 'Hourly Interest Rate',
    includeLowVolumeMarkets: 'Include low volume markets',
    initialMarginRatio: 'Initial Margin Ratio',
    lastFundingRate: 'Last Funding Rate',
    lastTwelveHours: 'Last 12 hours',
    lastTwentyFourHours: 'Last 24 hours',
    maintenanceMarginRatio: 'Maintenance Margin Ratio',
    minNotional: 'Min. Notional',
    makerFeeRate: 'Maker Fee Rate',
    market: 'Market',
    marketId: 'Market Id',
    marketsInfo: 'Markets Info',
    marketDetails: 'Market details',
    tabs: {
      [Pages.Markets]: 'Markets Info',
      [MarketsSubPage.RealtimeFundingRateSubPage]: 'Real-Time Funding Rate',
      [MarketsSubPage.FundingRateHistoryPage]: 'Funding Rate History',
      [MarketsSubPage.TradingRulesPage]: 'Trading Rules'
    },
    marketType: 'Market Type',
    minAmountMovement: 'Min. Amount Movement',
    minPriceMovement: 'Min. Price Movement',
    minPriceTickSize: 'Min. Price Tick Size',
    minQuantityTickSize: 'Min. Quantity Tick Size',
    nextFunding: 'Next Funding',
    oracleBase: 'Oracle Base',
    oracleQuote: 'Oracle Quote',
    oracleType: 'Oracle Type',
    oracleScaleFactor: 'Oracle Scale Factor',
    perDay: 'per day',
    price: 'Price',
    proposalId: 'Proposal ID',
    quoteDenom: 'Quote Denom',
    searchMarket: 'Search Market',
    spot: 'Spot',
    takerFeeRate: 'Taker Fee Rate',
    ticker: 'Ticker',
    time: 'Time',
    type: 'Type',
    usd: 'USD',
    volume: 'Volume (24H)',
    volumeInUsd: 'Volume (24h) in USD Value',
    oneHour: '1 hr',
    futures: 'Futures',

    toast: {
      notFound: `Failed to fetch Market with id {id}`
    }
  }
}
