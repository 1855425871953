export enum AccountSubPage {
  IndexPage = 'account-account-index',
  TransactionsPage = 'account-account-transactions',
  StakingPage = 'account-account-staking',
  StakingUnstakePage = 'account-account-staking-unstake',
  StakingRestakePage = 'account-account-staking-restake',
  NftPage = 'account-account-nft'
}

export enum AccountBalancesSubPage {
  IndexPage = 'account-account-index',
  Cw20Page = 'account-account-index-cw20'
}

export enum ContractSubPage {
  IndexPage = 'contract-contract',
  BalancePage = 'contract-contract-balances',
  CW20BalancePage = 'contract-contract-cw20balances',
  InitMsgPage = 'contract-contract-init',
  TokenHoldersPage = 'contract-contract-token-holders',
  ActionPage = 'contract-contract-action'
}

export enum TransactionSubPage {
  IndexPage = 'transaction-transaction',
  EventLogsPage = 'transaction-transaction-event-logs'
}

export enum BlockSubPage {
  IndexPage = 'block-block'
}

export enum CodeSubPage {
  IndexPage = 'code-code'
}

export enum MarketsSubPage {
  RealtimeFundingRateSubPage = 'markets-realtime-funding-rate',
  FundingRateHistoryPage = 'markets-funding-rate-history',
  TradingRulesPage = 'markets-trading-rules'
}

export enum TradeHistorySubPage {
  SpotPage = 'trade-history-spot',
  SpotDetailsPage = 'trade-history-spot-tradeId',
  DerivativesPage = 'trade-history-derivatives',
  DerivativesDetailsPage = 'trade-history-derivatives-tradeId'
}

export enum AssetSubPage {
  SmartContractsPage = 'assets-smart-contracts'
}

export enum SmartContractsSubPage {
  CodePage = 'smart-contracts-code'
}

export enum QueryAndExecuteSubPage {
  IndexPage = 'contract-contract-action',
  ExecutePage = 'contract-contract-action-execute'
}

export enum Pages {
  Index = 'index',
  Code = 'code',
  Blocks = 'blocks',
  Assets = 'assets',
  Markets = 'markets',
  Account = 'account-account-index',
  AssetDetail = 'asset-denom',
  Maintenance = 'maintenance',
  Transactions = 'transactions',
  MarketDetail = 'market-market',
  TradeHistory = 'trade-history',
  SmartContracts = 'smart-contracts',
  CommunitySpendPool = 'community-spend-pool'
}
