import { defineStore } from 'pinia'
import { DEFAULT_NETWORK } from '@/app/utils/constant'
import { NetworkType } from '@/types/network'
import { SearchStatusType, NetworkErrorType } from '@/types/enum'

type AppStoreState = {
  network: NetworkType
  selectedLanguage: string
  searchParam?: number | string
  searchStatus?: SearchStatusType
  networkErrorTypes: NetworkErrorType[]
}

const initialStateFactory = () => ({
  selectedLanguage: '',
  networkErrorTypes: [],
  searchParam: undefined,
  searchStatus: undefined,
  network: DEFAULT_NETWORK
})

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => initialStateFactory(),
  actions: {
    setSelectedLanguage(selectedLocale: string) {
      const appStore = useAppStore()

      appStore.$patch({ selectedLanguage: selectedLocale })
    }
  }
})
