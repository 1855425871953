import {
  Pages,
  WasmTableColumn,
  SmartContractsSubPage,
  QueryAndExecuteSubPage,
  SmartContractsTableColumn,
  ContractTokenHoldersTableColumn
} from '@/types'

export default {
  smartContracts: {
    table: {
      smartContracts: {
        [SmartContractsTableColumn.TxHash]: 'Tx Hash',
        [SmartContractsTableColumn.Address]: 'Address',
        [SmartContractsTableColumn.Creator]: 'Creator',
        [SmartContractsTableColumn.Executed]: 'Executed',
        [SmartContractsTableColumn.LastExecutedOrCreated]:
          'Last executed/Created',
        [SmartContractsTableColumn.ContractNameOrType]: 'Contract Name/Type'
      },
      wasm: {
        [WasmTableColumn.TxHash]: 'Tx Hash',
        [WasmTableColumn.Creator]: 'Creator',
        [WasmTableColumn.Version]: 'Version',
        [WasmTableColumn.IdOrType]: 'ID/Type',
        [WasmTableColumn.Instances]: 'Instances',
        [WasmTableColumn.CreationDate]: 'Creation Date'
      },
      tokenHolders: {
        [ContractTokenHoldersTableColumn.Address]: 'Address',
        [ContractTokenHoldersTableColumn.Balance]: 'Balance'
      }
    },

    tabs: {
      [Pages.SmartContracts]: 'Contracts',
      [SmartContractsSubPage.CodePage]: 'Code'
    },

    contract: {
      fee: 'Fee',
      time: 'Time',
      type: 'Type',
      admin: 'Admin',
      denom: 'Denom',
      funds: 'Funds',
      amount: 'Amount',
      height: 'Height',
      result: 'Result',
      codeId: 'Code ID',
      txHash: 'Tx Hash',
      address: 'Address',
      balance: 'Balance',
      created: 'Created',
      creator: 'Creator',
      decimal: 'Decimal',
      initMsg: 'InitMsg',
      cosmwasm: 'CosmWasm',
      balances: 'Balances',
      contract: 'Contract',
      executed: 'Executed',
      tokenInfo: 'Token info',
      tokenHolders: 'Token Holders',
      noInitMsg: 'No Init Msg',
      contractName: 'Contract Name',
      creationDate: 'Creation date',
      lastExecuted: 'Last executed',
      smartContract: 'Smart Contract',
      smartContracts: 'Smart Contracts',
      lastExecutedAt: 'Last executed at',
      searchContracts: 'Search contracts',
      updateContracts: 'Update Contracts',
      notFoundDescription: 'Please try again.',
      smartContractDetails: 'Smart contract details',
      invalidTitle: 'Oops! The following contract address format is incorrect:',
      invalidDescription: 'Please re-enter the contract address correctly.',
      notFoundTitle:
        'Sorry, We are unable to locate this contract on {network}:',

      queryAndExecute: {
        max: 'Max',
        copy: 'Copy',
        query: 'Query',
        search: 'Search',
        execute: 'Execute',
        balance: 'Balance:',
        queryMsg: 'Query Msg',
        title: 'Query/Execute',
        jsonInput: 'JSON Input',
        executeMsg: 'Execute Msg',
        formatJSON: 'Format JSON',
        queryResult: 'Query Result',
        attachFunds: 'Attach Funds',
        selectAsset: 'Select Asset',
        enterAmount: 'Enter Amount',
        addMoreAssets: 'Add more assets',
        suggestions: 'Message Suggestions',
        viewTransaction: 'View Transaction',
        successfullyCopied: 'Successfully copied',
        successfullyExecuted: 'Successfully executed',
        connectToExecute: 'Connect wallet to execute contracts',

        wallet: {
          connectWallet: 'Connect Wallet'
        },

        tabs: {
          [QueryAndExecuteSubPage.IndexPage]: 'Query',
          [QueryAndExecuteSubPage.ExecutePage]: 'Execute'
        }
      }
    },

    code: {
      id: 'Id',
      type: 'Type',
      idType: 'ID/Type',
      codeId: 'Code ID',
      txHash: 'Tx Hash',
      creator: 'Creator',
      version: 'Version',
      checksum: 'Checksum',
      contract: 'Contract',
      wasmCode: 'Wasm Code',
      instances: 'Instances',
      wasmCodes: 'Wasm codes',
      permission: 'Permission',
      codeDetail: 'Code detail',
      codeSchema: 'Code Schema',
      codeViewer: 'Code Viewer',
      proposalId: 'Proposal ID',
      updateCodes: 'Update Codes',
      creationDate: 'Creation Date',
      wasmCodeDetails: 'Wasm Code Details',
      permittedAddress: 'Permitted Address',
      notFoundDescription: 'Please try again.',
      invalidTitle: 'Oops! The following code id format is incorrect:',
      invalidDescription: 'Please re-enter the code id correctly.',
      notFoundTitle: 'Sorry, We are unable to locate this code on {network}:'
    }
  }
}
