import { BlockTableColumn } from '@/types'

export default {
  block: {
    table: {
      block: {
        [BlockTableColumn.Proposer]: 'Proposer',
        [BlockTableColumn.Height]: 'Block Height',
        [BlockTableColumn.Timestamp]: 'Timestamp',
        [BlockTableColumn.HeightOrTime]: 'Height/Time',
        [BlockTableColumn.Transactions]: 'Transactions',
        [BlockTableColumn.ProposerOrTxs]: 'Proposer/Txs'
      }
    },
    hash: 'Block hash',
    proposer: 'Proposer',
    title: 'Block details',
    height: 'Block height',
    recentBlocks: 'Recent Blocks',
    updateBlocks: 'Update Blocks',
    notFoundDescription: 'Please try again.',
    invalidTitle: 'Oops! The following block format is incorrect:',
    invalidDescription: 'Please re-enter the block height correctly.',
    notFoundTitle: 'Sorry, We are unable to locate this block on {network}:',

    stats: {
      blockTime: 'Block Time',
      blockHeight: 'Block Height',
      blockCount: 'Block Count (Last 24h)',
      transactions: 'Transactions (Last 24h)'
    }
  }
}
