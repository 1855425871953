export default {
  nft: {
    traits: '特征',
    creator: '创作者',
    tokenId: '代币 Id',
    contract: '合约',
    noNfts: '查无 NFT',
    notFound: '查无 NFT',
    description: '描述'
  },
  home: {
    stats: {
      injSupply: {
        marketCap: '市值',
        totalStaked: '当前总质押数量',
        currentSupply: 'INJ 流通供应量',
        tooltipContent: 'INJ 的 100% 供应量现已流通。当前流通供应量：'
      },
      transactions: {
        blockTime: '区块时间',
        blockHeight: '区块高度',
        totalTransactions: '总交易笔数'
      },
      assets: {
        tvl: '总资产价值',
        numberOfAssets: '资产数量',
        numberOfWallets: '钱包数量'
      },
      staked: {
        apr: '质押 APR',
        total: '总质押数量',
        injBurned: '总销毁 INJ 数量'
      }
    }
  },
  block: {
    table: {
      block: {
        proposer: '验证节点',
        height: '区块高度',
        timestamp: '时间戳',
        'height-or-time': '高度/时间',
        transactions: '交易',
        'proposer-or-txs': '验证节点/交易记录'
      }
    },
    hash: '区块哈希',
    proposer: '验证节点',
    title: '区块细节',
    height: '区块高度',
    recentBlocks: '当前区块',
    updateBlocks: '更细区块',
    notFoundDescription: '请重新尝试。',
    invalidTitle: '当前区块格式错误：',
    invalidDescription: '请重新输入正确的区块高度。',
    notFoundTitle: '无法于 {network} 找到此区块：',
    stats: {
      blockTime: '区块时间',
      blockHeight: '区块高度',
      blockCount: '过去 24 小时区块数量统计',
      transactions: '过去 24 小时交易数量统计'
    }
  },
  assets: {
    table: {
      denomHolders: {
        value: '价值',
        address: '地址',
        quantity: '数量',
        percentage: '占比'
      },
      smartContract: {
        token: '代币',
        'contract-address': '合约地址'
      },
      assets: {
        token: '代币',
        price: '价格',
        'total-usd-value': '美元价值',
        'current-supply': '当前流通量'
      }
    },
    name: '名称',
    type: '类别',
    denom: 'Denom',
    price: '价格',
    token: '代币',
    value: '价值',
    admin: '管理人',
    title: '资产',
    native: '原生',
    decimal: '位数',
    overview: '概览',
    usdValue: '美元价值',
    allAssets: '总资产',
    assetType: '资产类别',
    assetDetail: '资产细节',
    totalAssets: '总资产',
    searchToken: '查询代币',
    currentSupply: '当前供应',
    insuranceFund: '保险基金',
    smartContracts: '智能合约',
    adapterAddress: '适配器地址',
    creatorAddress: '创造者地址',
    contractAddress: '合约地址',
    submitTokenInfo: '提交代币信息',
    totalAssetValue: '总资产价值',
    tokenVerification: '代币验证',
    assetsOnInj: 'Injective 链上资产',
    includeUnknownTokens: '包含未知代币',
    stakedAssetValue: '质押中 INJ 资产价值',
    totalOnchainAssets: '总链上资产数量',
    submitTokenMetadata: '提交代币元数据',
    assetsOnSmartContracts: '智能合约上的资产',
    currentSupplyTimesPrice: '当前供应量乘以价格',
    adminNote: '铸造者地址拥有增发供应量的权限',
    tokensOnInjective: 'Injective 生态总流通代币数量',
    theAmountDisplayedMayNotBeInTheCorrectScale: '显示金额可能不符合正确比例',
    theInfoOfTokenExternalVerification:
      '此代币名称基于在其源网络上发现的信息。',
    toast: {
      assetNotFound: '未找到为 {denom} denom 的资产'
    },
    tabs: {
      assets: '代币',
      'assets-smart-contracts': '代币智能合约'
    },
    stakingTabs: {
      'account-account-staking': '质押',
      'account-account-staking-unstake': '解除质押',
      'account-account-staking-restake': '重新质押'
    },
    denomHolders: {
      title: '持有者',
      value: '价值',
      address: '地址',
      percentage: '占比',
      quantity: '数量'
    }
  },
  wallet: {
    beta: 'Beta',
    wallet: '钱包',
    popular: '总数',
    address: '地址',
    connect: '连接',
    overview: '概览',
    connected: '已连接',
    disconnect: '解除连接',
    otherWallets: '其他钱包',
    connectWallet: '连接钱包',
    connectAWallet: '连接一个钱包',
    connectedWallet: '已连接钱包',
    disconnectWallet: '解除连接钱包',
    connectUsingTorus: '单击登入',
    successfullyConnected: '成功链接',
    connectUsingBrowser: '使用浏览器钱包连接',
    connectUsingHardware: '使用硬件钱包连接',
    connectWalletToUseNotifications: '连接你的钱包以接收通知',
    hardwareWallet: {
      getAddresses: '获得地址',
      getMoreAddresses: '获得更多地址',
      selectAddressToConnect: '选取要连接地址',
      getAddressNote: '我们正在取得你的地址，请耐心等候...'
    },
    trezor: {
      trezor: 'Trezor',
      trezorBip32: 'Trezor',
      trezorBip44: 'Trezor Bip44',
      derivationPath: '派生路径',
      selectDerivationPath: '选取派生路径',
      note: '注意：为了确保连接 Trezor 硬件钱包时过程顺利，请确保您运行的是最新的 Chrome 版本，并且您的 Trezor 设备已连接并解锁。'
    },
    ledger: {
      ledgerLive: 'Ledger Live',
      ledgerLegacy: 'Ledger Legacy',
      derivationPath: '派生路径',
      selectDerivationPath: '选取派生路径',
      note: '注意：为了确保连接您的 Ledger 硬件钱包时过程顺利，请确保您运行的是最新的 Chrome 版本，并已连接、解锁您的 Ledger 设备，并且打开了您的以太坊应用程序。'
    },
    option: {
      leap: 'Leap',
      keplr: 'Keplr',
      ninji: 'Ninji',
      ledger: 'Ledger',
      trezorBip32: 'Trezor',
      trezorBip44: 'Trezor Bip44',
      phantom: 'Phantom',
      metamask: 'Metamask',
      'okx-wallet': 'OKX 钱包',
      BitGet: 'Bitget 钱包',
      'trust-wallet': 'Trust 钱包',
      cosmostation: 'Cosmostation',
      'wallet-connect': 'Wallet Connect'
    }
  },
  account: {
    table: {
      staked: {
        reward: '奖励',
        amount: '数量',
        validator: '验证节点'
      },
      restaking: {
        amount: '数量',
        'destination-validator': '目标验证节点',
        'source-validator': '源验证节点',
        'completition-time': '完成时间'
      },
      unstaking: {
        amount: '数量',
        validator: '验证节点',
        'completition-time': '完成时间'
      },
      balances: {
        price: '价格',
        amount: '数量',
        holding: '持有',
        'usd-value': 'USD 价值',
        'unrealized-pnl': '未实现盈亏',
        'in-use-reserved': '使用中/保留',
        'available-balance': '可用余额'
      },
      cw20Balances: {
        balance: '余额',
        holding: '持有',
        'contract-address': '合约地址'
      }
    },
    cw20: 'CW',
    ibc: 'IBC',
    bank: 'Bank',
    total: '总',
    value: '价值',
    amount: '数量',
    native: '原生',
    price: '价格',
    staked: '已质押',
    tokens: '代币',
    reward: '奖励',
    address: '地址',
    balance: '余额',
    holding: '持有',
    unknown: '未知',
    unstake: '解除质押',
    restake: '重新质押',
    balances: '余额',
    cosmwasm: 'CosmWasm',
    holdings: '持有',
    usdValue: 'USD 价值',
    validator: '验证节点',
    allAssets: '所有资产',
    assetType: '资产类别',
    unbondings: '解除绑定',
    injBalance: 'INJ 余额',
    totalValue: '总价值',
    details: '地址详细信息',
    delegations: '提名',
    bridgedERC20: '跨链 ERC20',
    cw20Balances: 'CW20 余额',
    pathToOrigin: '源头路径',
    insuranceFund: '保险基金',
    unrealizedPnl: '未实现盈亏',
    inUseReserved: '使用中/保留',
    tradingAccount: '交易账户',
    unknownNetwork: '未知网络',
    contractAddress: '合约地址',
    sourceValidator: '源验证节点',
    totalInjBalance: '总 INJ 余额',
    whatDoesItMean: '它代表什么？',
    availableBalance: '可用余额',
    claimableRewards: '可领取奖励数量',
    completitionTime: '完成时间',
    notFoundDescription: '请重新尝试。',
    latestTransactions: '最新交易',
    noUnstakingFound: '无解除质押中的资产。',
    insuranceFundToken: '保险基金代币',
    noStakedFound: '无质押中资产。',
    destinationValidator: '目标验证节点',
    noTransactionsFound: '查无交易。',
    noRestakingFound: '无重新质押中资产。',
    tradingAccountBalances: '交易账户余额',
    isBalanceBreakdownEnabled: '包含余额明细',
    invalidDescription: '请重新输入正确账户地址。',
    invalidTitle: '以下账户地址格式错误：',
    notFoundTitle: '无法于 {network} 查询此账户：',
    ibcAssetPathDisplay: 'IBC 资产有独特的方式来展示资产的来源和转移路径。',
    amountIncludes: '金额包括来自未结订单和保证金的未平仓仓位和未实现盈亏。',
    totalValueIncludes:
      '总值包括未平仓头寸的保证金和未实现盈亏以及未平仓订单和质押金额。请记住，头寸和未实现盈亏是于后台加载。',
    verified: '已验证',
    internalVerification: '已完成内部验证',
    externalVerification: '已完成外部验证',
    unverified: '未验证',
    tokenFactory: '代币工厂',
    tabs: {
      'account-account-index': '余额',
      'account-account-transactions': '交易',
      'account-account-staking': '质押',
      'account-account-nft': 'NFTs'
    },
    balanceTabs: {
      'account-account-index': 'Injective',
      'account-account-index-cw20': 'CW20'
    }
  },
  markets: {
    table: {
      fundingRate: {
        time: '时间',
        'funding-rate': '资金费率',
        'funding-interval': '资金间隔'
      },
      realTimeFundingRate: {
        markets: '市场',
        'next-funding': '下次资金',
        'hourly-interest-rate': '每小时利率',
        'estimated-funding-rate': '预期资金费率'
      },
      tradingRules: {
        markets: '市场',
        'min-notional': '最低名义金额',
        'base-maker-fee': '基础挂单费用',
        'base-taker-fee': '基础吃单费用',
        'min-price-movement': '最小价格变动',
        'min-amount-movement': '最小数量变动',
        'initial-margin-ratio': '初始保证金比率',
        'maintenance-margin-ratio': '维持保证金比率'
      },
      marketInfo: {
        type: '类别',
        price: '价格',
        markets: '市场',
        volume: '24 小时交易量',
        'volume-in-usd': '24 小时交易量（以美金计）'
      }
    },
    allMarkets: '所有市场',
    allTypes: '所有类别',
    baseDenom: '基础 Denom',
    baseMakerFee: '基础挂单费用',
    baseTakerFee: '基础吃单费用',
    derivative: '衍生品',
    estimatedFundingRate: '预期资金费率',
    exchangeRelayers: '交易所 / 中继层',
    fundingInterval: '资金间隔',
    fundingRate: '资金费率',
    hourlyInterestRate: '每小时利率',
    includeLowVolumeMarkets: '包含低交易量市场',
    initialMarginRatio: '初始保证金比率',
    lastFundingRate: '最新资金费率',
    lastTwelveHours: '过去 12 小时',
    lastTwentyFourHours: '过去 24 小时',
    maintenanceMarginRatio: '维持保证金比率',
    minNotional: '最低名义金额',
    makerFeeRate: '挂单费用比率',
    market: '市场',
    marketId: '市场 ID',
    marketsInfo: '市场信息',
    marketDetails: '市场详细信息',
    tabs: {
      markets: '市场信息',
      'markets-realtime-funding-rate': '实时资金费率',
      'markets-funding-rate-history': '资金费率历史',
      'markets-trading-rules': '交易规则'
    },
    marketType: '市场类别',
    minAmountMovement: '最低数量变动',
    minPriceMovement: '最低价格变动',
    minPriceTickSize: '最小价格变动单位',
    minQuantityTickSize: '最小数量变动单位',
    nextFunding: '下次资金',
    oracleBase: '预言机基础',
    oracleQuote: '预言机报价',
    oracleType: '预言机类别',
    oracleScaleFactor: '预言机比例因子',
    perDay: '每日',
    price: '价格',
    proposalId: '提案 ID',
    quoteDenom: '报价 Denom',
    searchMarket: '查询市场',
    spot: '现货',
    takerFeeRate: '吃单费用比率',
    ticker: '标的',
    time: '时间',
    type: '类别',
    usd: 'USD',
    volume: '24 小时交易量',
    volumeInUsd: '24 小时交易量（以美金计）',
    oneHour: '1 小时',
    futures: '期货',
    toast: {
      notFound: '无法检索 {id} ID 市场'
    }
  },
  spendPool: {
    table: {
      to: '至',
      from: '自',
      amount: '数量',
      timestamp: '时间戳',
      'block-height': '区块高度'
    },
    title: '社区池子/分发模块',
    to: '至 CSP',
    from: '自 CSP/DM',
    enterAddress: '输入地址',
    timeRange: '选取时间跨度',
    communitySpendPool: '社区池子',
    cspDistributionModule: 'CSP/分发模块',
    enterAddressToSeeResults: '请输入一个 Injective 地址以查看结果'
  },
  transaction: {
    table: {
      transactions: {
        type: '类别',
        time: '时间',
        block: '区块',
        status: '状态',
        amount: '数量',
        'txn-hash': '交易哈希',
        'tx-hash-and-type': '交易哈希/类别',
        'status-and-amount': '状态/数量'
      }
    },
    txs: 'Txs',
    fee: '费用',
    type: '类别',
    hash: '哈希',
    time: '时间',
    memo: 'Memo',
    view: '查看',
    items: '物件',
    height: '高度',
    events: '活动',
    sender: '传送者',
    newTxs: '新交易',
    txHash: '交易哈希',
    message: '信息',
    summary: '摘要',
    txnHash: '交易哈希',
    messages: '信息',
    errorLog: '错误记录',
    exportCsv: '汇出 CSV 档案',
    messageType: '信息类别',
    tokenTransfers: '代币转移',
    defiActivities: 'DeFi 活动',
    rawTransaction: '原始交易',
    transactionHash: '交易哈希',
    updateTransactions: '更新交易',
    transactionDetails: '交易细节',
    gasUsedAndRequested: 'Gas（已使用/已要求） ',
    noTransactionsFound: '查无交易。',
    filter: {
      types: '类别',
      reset: '重置',
      filters: '筛选',
      allTypes: '所有类别',
      messageType: '信息类别',
      selectAll: '选取全部',
      clearAll: '删除全部',
      resetMessages: '重置信息'
    },
    stats: {
      totalTransactions: '所有交易',
      transactionsThirtyDays: '30 日内交易',
      transactionsLast24Hours: '过去 24 小时交易',
      tps: 'TPS（过去 100 个区块）'
    },
    voter: '投票者',
    signer: '签署者',
    details: '细节',
    proposer: '验证节点',
    receiver: '接收者',
    depositor: '存款人',
    validator: '验证节点',
    subaccount: '子节点',
    orchestrator: 'Orchestrator',
    hashInvalidTitle: '你输入的交易哈希不可用：',
    hashInvalidDescription: '请重新输入正确的交易哈希。',
    notFoundTitle: '无法于 {network} 寻得以下交易：',
    notFoundDescription:
      'Injective Explorer 仅提供区块链当前状态的概述，例如您的交易状态。 Injective Explorer 不控制任何交易。',
    notFoundDescriptionPoint1:
      '1) 如果您刚提交交易，请等待至少 30 秒后再刷新此页面。',
    notFoundDescriptionPoint2:
      '2) 您的交易可能仍然在不同节点的 TX 池中，等待广播。',
    notFoundDescriptionPoint3:
      '3) 如果网络繁忙，您的交易可能需要一些时间才会显示。',
    notFoundDescriptionPoint4:
      '4) 如果 1 小时后您的交易仍未出现，请咨询您的发送者或交易所/钱包/交易提供商以获取更多信息。',
    proposal: {
      proposal: '提案',
      yes: '是',
      abstain: '弃权',
      no: '否',
      noWithVeto: '否决'
    },
    summaryType: {
      MsgExecuteContractCompatSwap:
        '{sender} 使用 {inputAmount} {inputSymbol} 兑换 {outputAmount} {outputSymbol}'
    },
    eventLogs: {
      title: '活动记录',
      logs: '记录'
    },
    claimId: '收取 #',
    claimIdNote:
      '以太坊存款会执行多个 MsgDepositClaim 交易。每笔存款均由唯一的 claimId 识别。',
    generatingCsvSuccessMessage: '成功生成 CSV 档案！',
    generatingCsvErrorMessage: '生成 CSV 文件时出现问题。请再试一次。'
  },
  tradeHistory: {
    table: {
      tradeHistory: {
        time: '时间',
        type: '类别',
        side: 'Side',
        amount: '数量',
        market: '市场',
        address: '地址',
        'trade-id': '交易 ID'
      }
    },
    buy: '购买',
    fee: '费用',
    time: '时间',
    type: '类别',
    side: 'Side',
    sell: '售出',
    event: '活动',
    filter: '筛选',
    payout: '支付',
    market: '市场',
    amount: '数量',
    address: '地址',
    tradeId: '交易 ID',
    timestamp: '时间戳',
    spotTrade: '现货交易',
    timeRange: '时间跨度',
    allMarkets: '所有市场',
    blockHeight: '区块高度',
    tradeActivity: '交易活动',
    tradeIdDetails: '交易 ID 细节',
    tradingActivity: '交易活动',
    tradeHistoryDaily: '24 小时内交易活动',
    clearFilters: '清除筛选条件',
    feeRecipient: '费用接收者',
    subaccountId: '子账户 ID',
    derivativeTrade: '衍生品交易',
    filterByAddress: '以地址筛选',
    tradeHistoryDetails: '交易历史细节',
    toast: {
      notFound: '无法获取 ID 为 {id} 的交易'
    },
    tabs: {
      'trade-history-spot': '现货',
      'trade-history-derivatives': '衍生品'
    }
  },
  smartContracts: {
    table: {
      smartContracts: {
        'tx-hash': '交易哈希',
        address: '地址',
        creator: '创建者',
        executed: '执行者',
        'last-executed-or-created': '最近执行/创建',
        'contract-name-or-type': '合约名称/类别'
      },
      wasm: {
        'tx-hash': '交易哈希',
        creator: '创建者',
        version: '版本',
        'id-or-type': 'ID/类别',
        instances: '实例',
        'creation-date': '创建日期'
      },
      tokenHolders: {
        address: '地址',
        balance: '余额'
      }
    },
    tabs: {
      'smart-contracts': '合约',
      'smart-contracts-code': '代码'
    },
    contract: {
      fee: '费用',
      time: '时间',
      type: '类别',
      admin: '管理者',
      denom: 'Denom',
      funds: '资金',
      amount: '数量',
      height: '高度',
      result: '结果',
      codeId: '代码 ID',
      txHash: '交易哈希',
      address: '地址',
      balance: '余额',
      created: '创建',
      creator: '创建者',
      decimal: '位数',
      initMsg: 'InitMsg',
      cosmwasm: 'CosmWasm',
      balances: '余额',
      contract: '合约',
      executed: '已执行',
      tokenInfo: '代币信息',
      tokenHolders: '代币持有者',
      noInitMsg: '无 Init Msg',
      contractName: '合约名称',
      creationDate: '创建日期',
      lastExecuted: '最近执行',
      smartContract: '智能合约',
      smartContracts: '智能合约',
      lastExecutedAt: '最近执行于',
      searchContracts: '搜索合约',
      updateContracts: '更新合约',
      notFoundDescription: '请重试。',
      smartContractDetails: '智能合约细节',
      invalidTitle: '以下合约地址不正确：',
      invalidDescription: '请重新输入正确合约地址：',
      notFoundTitle: '无法于 {network} 查看此合约：',
      queryAndExecute: {
        max: '最大',
        copy: '复制',
        query: '检索',
        search: '查询',
        execute: '执行',
        balance: '余额:',
        queryMsg: '检索 Msg',
        title: '检索/执行',
        jsonInput: 'JSON 输入',
        executeMsg: '执行 Msg',
        formatJSON: 'JSON 格式',
        queryResult: '检索结果',
        attachFunds: '附加资金',
        selectAsset: '选取资产',
        enterAmount: '输入数量',
        addMoreAssets: '添加更多资产',
        suggestions: '信息推荐',
        viewTransaction: '查看交易',
        successfullyCopied: '已成功复制',
        successfullyExecuted: '已成功执行',
        connectToExecute: '连接钱包以执行合约',
        wallet: {
          connectWallet: '连接钱包'
        },
        tabs: {
          'contract-contract-query-and-execute': '检索',
          'contract-contract-query-and-execute-execute': '执行'
        }
      }
    },
    code: {
      id: 'Id',
      type: '类别',
      idType: 'ID/类别',
      codeId: '代码 ID',
      txHash: '交易哈希',
      creator: '创建者',
      version: '版本',
      checksum: 'Checksum',
      contract: '合约',
      wasmCode: 'Wasm 代码',
      instances: '实例',
      wasmCodes: 'Wasm 代码',
      permission: '许可',
      codeDetail: '代码细节',
      codeSchema: '代码架构',
      codeViewer: '代码查看者',
      proposalId: '提案 ID',
      updateCodes: '更新代码',
      creationDate: '创建日期',
      wasmCodeDetails: 'Wasm 代码细节',
      permittedAddress: '已许可地址',
      notFoundDescription: '请重试。',
      invalidTitle: '以下代码 ID 格式不正确：',
      invalidDescription: '请重新输入正确代码 ID。',
      notFoundTitle: '无法于 {network} 查看此代码：'
    }
  },
  common: {
    via: '通过',
    code: '代码',
    back: '返回',
    sent: '发送',
    type: '类别',
    dApps: 'DApps',
    block: '区块',
    fail: '失败',
    amount: '数量',
    assets: '资产',
    blocks: '区块',
    wasmCode: '代码',
    status: '状态',
    failed: '失败',
    inputs: '输入',
    update: '更新',
    details: '细节',
    outputs: '输出',
    account: '账户',
    success: '成功',
    markets: '市场',
    chainId: '链 Id',
    viewAll: '查看全部',
    overview: '概览',
    received: '已接收',
    contracts: '合约',
    search: '查询',
    time: '时间',
    timestamp: '时间戳',
    transaction: '交易',
    transactions: '交易记录',
    analytics: '分析',
    activity: '活动',
    all: '全部',
    from: '自',
    to: '至',
    cosmWasm: 'CosmWasm',
    rawJson: '原始 JSON 档案',
    noItems: '暂无记录。'
  },
  navigation: {
    hub: 'Hub',
    network: '网络',
    trading: '交易',
    ecosystem: '生态系统',
    validators: '验证节点',
    developers: '开发者'
  },
  network: {
    terra: 'Terra',
    evmos: 'Evmos',
    axelar: 'Axelar',
    cosmos: 'Cosmos',
    osmosis: 'Osmosis',
    ethereum: 'Ethereum',
    chihuahua: 'ChiHuaHua',
    persistence: 'Persistence',
    injective: 'Injective Chain',
    mainnet: '主网',
    testnet: '测试网'
  },
  toast: {
    codeIdCopied: '代码 ID 已复制到剪贴板',
    addressCopied: '地址 ID 已复制到剪贴板',
    tradeIdCopied: '交易 ID 已复制到剪贴板',
    marketIdCopied: '市场 ID 已复制到剪贴板',
    denomHashCopied: 'Denom 哈希 ID 已复制到剪贴板',
    txHashCopied: '交易哈希已复制到剪贴板',
    blockNumberCopied: '区块高度已复制到剪贴板',
    contractCopied: '合约地址已复制到剪贴板',
    contractInitMsgCopied: '合约 initMsg 已复制到剪贴板',
    contractFundCopied: '合约资金细节已复制到剪贴板',
    contractTokenInfoCopied: '合约代币信息已复制到剪贴板'
  },
  networkError: {
    accountPageBankBalances: '网络错误：无法获取余额。',
    accountPageCW20Balances: '网络错误：无法获取余额。',
    accountPageSubaccountBalances: '网络错误：无法获取余额。',
    accountPageTransactions: '网络错误：无法获取余额。',
    blockDetailsPage: '网络错误：无法获取区块。',
    blocksPageBlocks: '网络错误：无法获取区块。',
    contractDetailsPage: '网络错误：无法获取合约。',
    contractsPageContracts: '网络错误：无法获取合约。',
    contractsPageAccountsBalance: '网络错误：无法获取代币持有者。',
    contractPageTransactions: '网络错误：无法获取交易。',
    transactionDetailsPage: '网络错误：无法获取交易。',
    transactionsPageTransactions: '网络错误：无法获取交易。',
    wasmCodePageCodes: '网络错误：无法获取代码。',
    wasmCodeDetailsPage: '网络错误：无法获取代码。',
    communitySpendPool: '网络错误：无法获取交易。',
    denomHolders: '网络错误：无法获取代币持有者。'
  },
  noRecord: {
    accountPageBankBalances: '查无余额。',
    accountPageCW20Balances: '查无余额。',
    accountPageSubaccountBalances: '查无余额。',
    accountPageTransactions: '查无交易。',
    blocksPageBlocks: '查无区块。',
    contractsPageContracts: '查无合约。',
    contractsPageAccountsBalance: '查无代币持有者。',
    genericNoRecordsFound: '查无记录。',
    tradeHistory: '无法获取交易历史。',
    transactionsPageTransactions: '查无交易。',
    wasmCodePageCodes: '合约即将推出！',
    noResultsFound: '查无 {search} 结果',
    communitySpendPool: '查无结果。',
    denomHolders: '查无代币持有者。'
  },
  pagination: {
    showRows: '显示行'
  },
  FilterClearFilterBtn: '清除筛选条件',
  FilterApplyBtn: '筛选',
  notFound: '404 Not Found',
  searchPlaceHolder: '查询交易、区块或地址',
  FooterCopyRightMsg:
    'Copyright © {year} Injective Foundation. All rights reserved.',
  maintenance: {
    header: '更新进行中',
    description: '我们正在努力改善您的体验，很快就会回来。'
  },
  bgLoadingNote: '为了改善用户体验，我们正在后台加载外部信息。',
  notFound404: {
    title: '查无页面',
    description: '你所寻找的页面不存在。',
    backToHome: '回到主页'
  },
  injNameDoesNotExist: '{injName} 不存在。您可以在 {link} 处获取。'
}
