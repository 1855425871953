<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { cva } from 'class-variance-authority'
import type { VariantProps } from 'class-variance-authority'
import { Status, StatusType } from '@injectivelabs/utils'
import { RouteLocationRaw } from '#vue-router'

const buttonClasses = cva(
  [
    'text-center',
    'rounded',
    'bg-black',
    'text-uiGray-300',
    'font-medium',
    'disabled:opacity-50',
    'disabled:cursor-not-allowed'
  ],
  {
    variants: {
      intent: {
        primary: 'bg-uiPrimary-400 hover:bg-opacity-60 text-white',
        'primary-outline':
          'border bg-transparent border-uiPrimary-400 hover:opacity-60 text-uiPrimary-400',
        'light-blue-outline':
          'text-blue-200 border border-blue-200  bg-transparent hover:opacity-60',
        'dark-uiGray-outline':
          'text-uiPrimary-100 border border-uiGray-100 bg-transparent hover:text-purple-400 hover:border-purple-400',
        'black-outline': 'text-uiBlack border border-uiBlack bg-transparent'
      },
      size: {
        xxs: 'p-1 text-xs',
        xs: 'py-1 px-3 text-xs',
        sm: 'p-2 text-xs',
        md: 'px-6 py-1.5 text-sm leading-5',
        lg: 'px-12 py-2.5 text-base leading-5'
      }
    }
  }
)

type ButtonProps = VariantProps<typeof buttonClasses>

const props = withDefaults(
  defineProps<{
    class?: string | string[] | Record<string, boolean>
    status?: Status
    external?: boolean
    isLoading?: boolean
    isDisabled?: boolean
    to?: RouteLocationRaw
    size?: ButtonProps['size']
    intent?: ButtonProps['intent']
  }>(),
  {
    intent: 'primary',
    size: 'md',
    class: '',
    status: () => new Status(StatusType.Idle),
    to: undefined,
    external: false
  }
)

const component = computed(() =>
  props.to ? resolveComponent('NuxtLink') : 'button'
)
</script>

<template>
  <component
    :is="component"
    :disabled="props.isDisabled || status.isLoading()"
    :class="twMerge(buttonClasses({ intent, size, class: props.class }))"
    :to="props.to"
    :external="props.external"
    :target="props.external ? '_blank' : undefined"
  >
    <div
      v-if="status.isLoading() || isLoading"
      class="w-4 h-4 rounded-full border border-current border-l-transparent animate-spin mr-2"
    />
    <slot v-else />
  </component>
</template>
