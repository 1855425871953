<script setup lang="ts">
definePageMeta({ layout: 'default' })

function onRedirectHome() {
  navigateTo('/')
}
</script>
<template>
  <NuxtLayout>
    <h1 class="tracking-2 pb-8 text-2xl font-bold uppercase leading-8">
      {{ $t('notFound') }}
    </h1>

    <div class="relative">
      <AppCard>
        <AppNotFoundPlaceholder>
          <template #title>
            <span class="capitalize-phrase">
              {{ $t('notFound404.title') }}
            </span>
          </template>
          <template #value>
            <span class="break-all">
              {{ $t('notFound404.description') }}
            </span>
          </template>
          <template #description>
            <AppButton @click="onRedirectHome">
              {{ $t('notFound404.backToHome') }}
            </AppButton>
          </template>
        </AppNotFoundPlaceholder>
      </AppCard>
    </div>
  </NuxtLayout>
</template>
