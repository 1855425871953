import nft from './en/nft'
import home from './en/home'
import block from './en/block'
import assets from './en/assets'
import wallet from './en/wallet'
import account from './en/account'
import markets from './en/markets'
import spendPool from './en/spend-pool'
import transaction from './en/transaction'
import tradeHistory from './en/trade-history'
import smartContracts from './en/smart-contracts'
import { NetworkErrorType } from '@/types'

export default {
  ...nft,
  ...home,
  ...block,
  ...assets,
  ...wallet,
  ...account,
  ...markets,
  ...spendPool,
  ...transaction,
  ...tradeHistory,
  ...smartContracts,

  common: {
    via: 'via',
    code: 'Code',
    back: 'Back',
    sent: 'sent',
    type: 'Type',
    dApps: 'DApps',
    block: 'Block',
    fail: 'Failed',
    amount: 'Amount',
    assets: 'Assets',
    blocks: 'Blocks',
    wasmCode: 'Code',
    status: 'Status',
    failed: 'Failed',
    inputs: 'Inputs',
    update: 'Update',
    details: 'Details',
    outputs: 'Outputs',
    account: 'Account',
    success: 'Success',
    markets: 'Markets',
    chainId: 'Chain Id',
    viewAll: 'View all',
    overview: 'Overview',
    received: 'received',
    contracts: 'Contracts',
    search: 'Search',
    time: 'Time',
    timestamp: 'Timestamp',
    transaction: 'Transaction',
    transactions: 'Transactions',
    analytics: 'Analytics',
    activity: 'Activity',
    all: 'All',
    from: 'From',
    to: 'To',
    cosmWasm: 'CosmWasm',
    rawJson: 'Raw JSON',
    noItems: 'No items.'
  },

  navigation: {
    hub: 'Hub',
    network: 'Network',
    trading: 'Trading',
    ecosystem: 'Ecosystem',
    validators: 'Validators',
    developers: 'Developers'
  },

  network: {
    terra: 'Terra',
    evmos: 'Evmos',
    axelar: 'Axelar',
    cosmos: 'Cosmos',
    osmosis: 'Osmosis',
    ethereum: 'Ethereum',
    chihuahua: 'ChiHuaHua',
    persistence: 'Persistence',
    injective: 'Injective Chain',
    mainnet: 'Mainnet',
    testnet: 'Testnet'
  },

  toast: {
    codeIdCopied: 'Code id copied to your clipboard',
    addressCopied: 'Address copied to your clipboard',
    tradeIdCopied: 'Trade ID copied to your clipboard',
    marketIdCopied: 'Market ID copied to your clipboard',
    denomHashCopied: 'Denom hash copied to your clipboard',
    txHashCopied: 'Transaction hash copied to your clipboard',
    blockNumberCopied: 'Block height copied to your clipboard',
    contractCopied: 'Contract address copied to your clipboard',
    contractInitMsgCopied: 'Contract initMsg copied to your clipboard',
    contractFundCopied: 'Contract funds details copied to your clipboard',
    contractTokenInfoCopied: 'Contract token info copied to your clipboard'
  },

  networkError: {
    [NetworkErrorType.AccountPageBankBalances]:
      'Network error: Unable to fetch balances.',
    [NetworkErrorType.AccountPageCW20Balances]:
      'Network error: Unable to fetch balances.',
    [NetworkErrorType.AccountPageSubaccountBalances]:
      'Network error: Unable to fetch balances.',
    [NetworkErrorType.AccountPageTransactions]:
      'Network error: Unable to fetch transactions.',
    [NetworkErrorType.BlockDetailsPage]:
      'Network error: Unable to fetch block.',
    [NetworkErrorType.BlocksPageBlocks]:
      'Network error: Unable to fetch blocks.',
    [NetworkErrorType.ContractDetailsPage]:
      'Network error: Unable to fetch contract.',
    [NetworkErrorType.ContractsPageContracts]:
      'Network error: Unable to fetch contracts.',
    [NetworkErrorType.ContractsPageAccountsBalance]:
      'Network error: Unable to fetch token holders.',
    [NetworkErrorType.ContractPageTransactions]:
      'Network error: Unable to fetch transactions.',
    [NetworkErrorType.TransactionDetailsPage]:
      'Network error: Unable to fetch transaction.',
    [NetworkErrorType.TransactionsPageTransactions]:
      'Network error: Unable to fetch transactions.',
    [NetworkErrorType.WasmCodePageCodes]:
      'Network error: Unable to fetch codes.',
    [NetworkErrorType.WasmCodeDetailsPage]:
      'Network error: Unable to fetch code.',
    [NetworkErrorType.CommunitySpendPool]:
      'Network error: Unable to fetch transactions.',
    [NetworkErrorType.DenomHolders]:
      'Network error: Unable to fetch token holders.'
  },

  noRecord: {
    [NetworkErrorType.AccountPageBankBalances]: 'No balances found.',
    [NetworkErrorType.AccountPageCW20Balances]: 'No balances found.',
    [NetworkErrorType.AccountPageSubaccountBalances]: 'No balances found.',
    [NetworkErrorType.AccountPageTransactions]: 'No transactions found.',
    [NetworkErrorType.BlocksPageBlocks]: 'No blocks found.',
    [NetworkErrorType.ContractsPageContracts]: 'No smart contracts found.',
    [NetworkErrorType.ContractsPageAccountsBalance]: 'No token holders found.',
    [NetworkErrorType.GenericNoRecordsFound]: 'No records found.',
    [NetworkErrorType.TradeHistory]: 'Failed to fetch trade history.',
    [NetworkErrorType.TransactionsPageTransactions]: 'No transactions found.',
    [NetworkErrorType.WasmCodePageCodes]: 'Contracts coming soon!',
    [NetworkErrorType.CommunitySpendPool]: 'No results found.',
    [NetworkErrorType.DenomHolders]: 'No token holders found.',
    noResultsFound: 'No results for {search}'
  },

  pagination: {
    showRows: 'Show rows'
  },

  // mics
  FilterClearFilterBtn: 'Clear filters',
  FilterApplyBtn: 'Filter',
  notFound: '404 Not Found',
  searchPlaceHolder: 'Search for a transaction, block or address',

  // Footer
  FooterCopyRightMsg:
    'Copyright © {year} Injective Foundation. All rights reserved.',

  // Maintenance:
  maintenance: {
    header: 'Ongoing Upgrade',
    description:
      'We are working on improving your experience. We will be back soon.'
  },
  bgLoadingNote:
    'To improve UX we are loading external information in the background.',

  notFound404: {
    title: 'Page Not Found',
    description: 'The page you are looking for does not exist.',
    backToHome: 'Back to home page'
  },

  // Inj Name Service
  injNameDoesNotExist: '{injName} does not exist. You can claim it at {link}'
}
